import { Metrics, ProjectPriority, ProjectTrackingReportResponse, UserShort } from 'src/app/shared';
import { WidgetDashboardCode } from 'src/app/site-management/widget-board';
import { BaseAttachment } from './attachment.model';
import { BoardEpic } from './board-epic.model';
import { BoardLabel } from './board-label.model';
import { BoardRelease } from './board-release.model';
import { BoardSprint } from './board-sprint.model';
import { BoardStatus } from './board-status.model';
import { CustomField } from './custom-field.model';
import { TaskLinkType } from './index';
import { Project, ProjectPlatform } from './project.model';

export class Task {
  id: number;
  name: string;
  description: string;
  attachments: TaskAttachment[];
  dueDate: string;
  priority: ProjectPriority;
  assignee: UserShort;
  reporter: UserShort;
  cc: UserShort[];
  type: TaskType;
  project: Project;
  showTaskPlatform: boolean;
  sprint: BoardSprint;
  timeOriginalEstimate: number;
  epic: BoardEpic;
  labels: BoardLabel[];
  privateFlg: boolean;
  followUp: boolean;
  status: BoardStatus;
  key: string;
  jiraUrl: string;
  jiraDescription?: any; // adf format
  createdAt: string;
  modifiedAt: string;
  createdBy: UserShort;
  updatedBy: UserShort;
  deleted: boolean;
  customFields: TaskCustomField[];
  versions: BoardRelease[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  sortLinkedTasks: TaskLinkGroupBy[];
  parentTask: Task;
  storyPoint: number;
  storylineEstimates: TaskStorylineEstimate[];
  storyline: Task;
  storylineId: number;
  platform: ProjectPlatform;
  progress: number;
  metric: Metrics;
  metricValue: number;
  currentMetricValue: number;
  startMetricValue: number;
  platformId?: number;
  platformTasks?: Task[];
  colorCode?: string;
  countStoryPoint?: number;
  taskTemplate?: boolean;
  template?: Task;
  // for update
  projectId?: number;
  typeId?: number;
  statusId?: number;
  subTasks?: SubTaskResponse[];
  bookmark?: boolean;
  widgetCode?: WidgetDashboardCode;
  isGetDescription?: boolean;
  reminder: TaskReminder;
  isEditable?: boolean;
  hasSubTask?: boolean;
}

export interface TaskLinkGroupBy {
  type: TaskLinkType;
  linkedTasks: TaskLink[];
}

export interface TaskLink {
  id: number;
  linkedTask: TaskSortResponse;
  type: TaskLinkType;
  createdAt: string;
  modifiedAt: string;
}

export interface Attachment {
  name: string;
  url: string;
  objectUrl?: string;
}

export interface TaskUpsertFile {
  file: File;
  type: TaskAttachmentType;
  previewUrl?: string;
  blobUrl?: string;
}

export type TaskAttachmentType = 'Comment' | 'Description' | 'Other';
export enum TaskAttachmentAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
};

export class TaskAttachment extends BaseAttachment { }

export interface TaskUpdateRequest {
  name: string;
  description?: string;
  attachments?: TaskAttachment[];
  dueDate?: string;
  priorityId?: number;
  assigneeId?: number;
  reporterId?: number;
  ccIds?: number[];
  typeId: number;
  projectId: number;
  sprintId?: number;
  timeOriginalEstimate?: number;
  storyPoint?: number;
  epicId?: number;
  labelIds?: number[];
  versionIds?: number[];
  privateFlg?: boolean;
  statusId: number;
}

export enum TaskPatchType {
  name = 'name', description = 'description', dueDate = 'dueDate', priorityId = 'priorityId', assigneeId = 'assigneeId', reporterId = 'reporterId', ccIds = 'ccIds', typeId = 'typeId', projectId = 'projectId', sprintId = 'sprintId', timeOriginalEstimate = 'timeOriginalEstimate', storyPoint = 'storyPoint', epicId = 'epicId', labelIds = 'labelIds', versionIds = 'versionIds', statusId = 'statusId', startDate = 'startDate', endDate = 'endDate', privateFlg = 'privateFlg', colorCode = 'colorCode', followUp = 'followUp', storylineEstimates = 'storylineEstimates', storylineId = 'storylineId', platformId = 'platformId', metricId = 'metricId', metricValue = 'metricValue', progress = 'progress',
  currentMetricValue = 'currentMetricValue',
}

export interface TaskPatch {
  type: string | TaskPatchType;
  value: string | Date | number;
}

export interface TaskPatchMulti {
  task: TaskPatch[];
}

export interface TaskPatchError {
  code: string;
  message: string;
  errors: any[];
  context: string;
}

export class TaskType {
  id: number;
  name: string;
  colorCode: string;
  defaultFlg?: boolean;
  icon: string;
  deleted?: boolean;
  displayValue: string;
  code: string;
}

export interface TaskCustomField {
  id: number;
  field: CustomField;
  value: string;
}

export interface TaskTrackingReportResponse {
  id: number;
  name: string;
  key: string;
  jiraUrl: string;
  project: ProjectTrackingReportResponse;
}


export class WorkItemType extends TaskType{
  // id: number;
  // name: string;
  // colorCode: string;
  // icon: string;
  // defaultFlg: boolean;
  // deleted: boolean;
}

export interface TaskSortResponse {
  id: number;
  name: string;
  description: string;
  key: string;
  type: TaskType;
  jiraUrl: string;
}

export interface Release {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  deleted: boolean;
  status: string;
}

export interface WorkItemLabel {
  id: number;
  name: string;
  deleted: boolean;
  colorCode: string;
}


export interface TaskTrackingReportSearchRequest extends TaskSearchRequest {}

export interface TaskLogTimeSearchRequest {
  taskId: number;
  userId?: number;
  date?: string;
}

export interface TaskStatusItem {
  id: number;
  name: string;
  colorCode: string;
  defaultFlg: boolean;
  icon: string;
  deleted: boolean;
  orderNumber: number;
  numberTask: number;
}
export interface LinkTaskRequest {
  taskId: number;
  linkTypeId: number;
}

export interface TaskSearchRequest {
  keyword?: string;
  releaseIds?: number[];
  excludeReleaseIds?: number[];
  projectIds?: number[];
  statusIds?: number[];
  fromDate?: string;
  toDate?: string;
  userIds?: number[];
  reporterIds?: number[];
  teamIds?: number[];
  groupIds?: number[];
  typeIds?: number[];
  epicIds?: number[];
  projectId?: number;
  priorityIds?: number[];
  platformIds?: number[];
  sprintIds?: number[];
  showStorylineFlg?: boolean;
  showClosedTaskFlg?: boolean;
  taskIds?: number[];
  okrId?: number;
  labelIds?: number[];
  metricIds?: number[];
  keyResultId?: number;
  taskBacklogFlg?: boolean;
  includeUnassigned?: boolean;
  includeSubtaskFlg?: boolean;
  startDate?: string;
  endDate?: string;
  fromCalendar?: boolean;
  excludeIds?: number[];
  taskTemplate?: boolean;
  unAssigned?: boolean;
}

export interface TaskStorylineEstimate {
  id: number;
  value: number;
  platformId?: number;
  platform?: ProjectPlatform;
}

export interface TaskInStoryline {
  id: number;
  name: string;
  dueDate: string;
  priority: ProjectPriority;
  assignee: UserShort;
  type: TaskType;
  sprint: BoardSprint;
  timeOriginalEstimate: number;
  storyPoint: number;
  status: BoardStatus;
  key: string;
  jiraUrl: string;
  deleted: boolean;
  versions: BoardRelease[];
  createdAt: string;
  modifiedAt: string;
  project: Project;
  platform: ProjectPlatform;
  showTaskPlatform: boolean;
}

export interface SubTaskResponse {
  id: number;
  name: string;
  key: string;
  type: TaskType;
}

export interface TaskReminder {
  id?: number;
  start: string;
  nextStart: string;
  message?: string;
  ccRemind?: boolean;
  reporterRemind?: boolean;
  repeat?: ReminderRepeat | null;
}

export interface TaskReminderRequest extends TaskReminder {
  repeat?: ReminderRepeatRequest | null;
}

export interface ReminderRepeat {
  id?: number;
  times: number;
  period: string;
  endOfMonth?: boolean;
  stop?: StopTime;
  nonRemindDays?: NonRemindDay[];
  weekDays: WeekDayRemind;
  remindDays?: number[];
  weekendRemind: boolean;
  holidayRemind: boolean;
}

export interface ReminderRepeatRequest extends ReminderRepeat {
  stop?: StopTimeRequest;
}

export interface WeekDayRemind {
  id?: number;
  dayCode: string;
  status: string;
}

export interface NonRemindDay {
  id?: number;
  date: string;
}

export interface StopTime {
  id?: number;
  type: string;
  dueDate?: string;
  occurrences?: number;
  usage?: number;
  stopStatus?: boolean;
  statuses?: number[] | BoardStatus[];
}

export interface StopTimeRequest extends StopTime {
  statusIds?: number[];
}

export interface WeekDayReminderDisplay {
  day: string;
  active: boolean;
}

export interface StopTimeReminderDisplay {
  name: string;
  value: string;
  active: boolean;
}

export interface TaskFieldDetail {
  type: string;
  operators: string[];
  childs: TaskFieldChild[];
}

export interface TaskFieldChild {
  name: string;
  type: string;
}

export interface RemindDayDisplay {
  day: number;
  active: boolean;
}

export interface FieldSuggestionRequest {
  field: string;
  query?: string;
  includeId?: boolean;
  keyword?: string;
}

export interface TaskCloneRequest {
  jobTitleIds?: number[];
  taskIds: number[];
  cloneSubTask?: boolean;
  cloneTemplate?: boolean;
  name?: string;
}

export interface TaskClones {
  tasks: Task[];
}

export interface TaskDescription {
  description: string;
}
export interface MoveMultiTasksRequest {
  sprintIdTo: number;
  listIds: number[];
  idBefore?: number;
  idAfter?: number;
  placement?: MoveTaskPlacement;
}

export enum MoveTaskPlacement {
  top = 'top',
  bottom = 'bottom',
}

export interface UnsavedCache {
  [user: string]: UnsavedTaskCache;
}

export interface UnsavedTaskCache {
  [taskKey: string]: UnsavedItem;
}

export interface UnsavedItem {
  taskKey?: string;
  description?: string;
  comment?: string;
  [commentId: string]: string;
}

export interface TaskListColumn {
  name: string;
  key: string;
  active: boolean;
}

export interface TaskGeneratePayload {
  storylineId: number;
  tasks: {
    platformId: number;
    storyPoint: number;
  }[];
}

export interface TaskTimePayload {
  startDate?: string | Date;
  endDate?: string | Date;
  startTime?: string;
  endTime?: string;
}

export interface TaskConfig {
  [visibleKey: string]: boolean;
}

export interface TaskContributor {
  id: number;
  progress: number;
  user: UserShort;
  storyPoint: number;
  estimate: number;
  logWorkTime: number;
  startDate: string;
  endDate: string;
  dueDate: string;
  startTime: string;
  endTime: string;
}

export interface TaskContributorRequest extends Partial<TaskContributor> {
  userId?: number;
}

export type TaskElementSize = 'sm' | 'lg';
