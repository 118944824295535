<div class="task-detail-contributor-upsert" [formGroup]="form">
  <app-modal-container>
    <ng-container header>
      <x-i18n [key]="'Task\'s Contributors'"></x-i18n>
    </ng-container>

    <ng-container body>
      <!-- Progress area -->
      <div class="form-group mb-4">
        <div class="flex items-center mb-2">
          <div class="group">
            <label><x-i18n [key]="'Progress'"></x-i18n></label>
            <span class="progress-value ml-2">
              {{ form.get('progress').value }}%
            </span>
          </div>
          <div class="group">
            <label><x-i18n [key]="'Overall progress'"></x-i18n></label>
            <span class="progress-value ml-2">
              {{ overallProgress }}%
            </span>
          </div>
        </div>
        <x-progress formControlName="progress" [typing]="false"></x-progress>
      </div>

      <!-- Form area -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
        <div class="form-group">
          <label class="required"><x-i18n [key]="'Assignee'"></x-i18n></label>
          <ng-select
            formControlName="user"
            appendTo="body"
            [placeholder]="'None' | translate"
            [items]="users"
            [clearable]="false"
            [searchable]="true"
            [typeahead]="userTypeahead$"
          >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <div class="custom-option">
                <app-avatar [url]="item.avatar" [hideTooltip]="true"></app-avatar>
                <span class="option-label">{{item.fullName}}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'Story Point'"></x-i18n></label>
          <x-input type="number" [placeholder]="'None' | translate" formControlName="storyPoint"></x-input>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'Logwork'"></x-i18n></label>
          <x-input [placeholder]="'0h'" formControlName="logWorkTime"></x-input>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'Due Date'"></x-i18n></label>
          <x-date [dateFormat]="DATE_FORMAT" formControlName="dueDate"></x-date>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'Start Date'"></x-i18n></label>
          <x-date [dateFormat]="DATE_FORMAT" formControlName="startDate"></x-date>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'End Date'"></x-i18n></label>
          <x-date [dateFormat]="DATE_FORMAT" formControlName="endDate"></x-date>
        </div>

        <div class="col-span-2 custom-invalid-feedback" *ngIf="form.touched && form.hasError('invalidStartDate')">
          <x-i18n [key]="'endDateGreaterOrEqualStartDate'"></x-i18n>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'Start Time'"></x-i18n></label>
          <x-time formControlName="startTime" placeholder="00:00 AM" [defaultValue]="formControlLabel.StartTime | getDefaultValueTimeControl"></x-time>
          <x-form-error
            controlName="startTime"
            [form]="form"
            [customError]="{ error: 'invalid12TimeFormat', message: 'invalid12TimeFormat' }">
          </x-form-error>
        </div>

        <div class="form-group">
          <label><x-i18n [key]="'End Time'"></x-i18n></label>
          <x-time formControlName="endTime" placeholder="00:00 AM" [defaultValue]="formControlLabel.EndTime | getDefaultValueTimeControl"></x-time>
          <x-form-error
            controlName="endTime"
            [form]="form"
            [customError]="{ error: 'invalid12TimeFormat', message: 'invalid12TimeFormat' }">
          </x-form-error>
        </div>
      </div>

      <!-- Time sheet area -->
      <div class="mt-3" *ngIf="canShowTimeSheet | function : object : loggedUser">
        <app-task-detail-timesheet [task]="task" [userId]="object.user.id" [isCollapsed]="false"></app-task-detail-timesheet>
      </div>
    </ng-container>

    <ng-container footer>
      <div class="text-right">
        <button type="button" class="btn btn-white mr-3" (click)="onCancel()">
          <x-i18n [key]="'Cancel'"></x-i18n>
        </button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">
          <x-i18n [key]="'Save'"></x-i18n>
        </button>
      </div>
    </ng-container>
  </app-modal-container>
</div>
